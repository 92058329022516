import React from "react";
import Image from "next/image";
import Link from "next/link";
import ContentPlacer from "./ContentPlacer";

export default function Footer() {
  return (
    <>
      <footer className="mt-auto bg-gray-100" aria-labelledby="footer-heading">
        <ContentPlacer>
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-6 xl:col-span-1">
                <Image
                  src="/img/SE-Logo-Mark-Gray.png"
                  width="50"
                  height="50"
                  className="py-0"
                  alt="SummerEyes"
                />
                <p className="text-base text-gray-500">
                  Save time by summarizing any text on the internet.
                </p>
                <div className="flex space-x-6">
                  <a
                    href="https://twitter.com/SummerEyes_AI"
                    target="_blank"
                    className="text-gray-400 hover:text-summer-base"
                  >
                    <span className="sr-only">Twitter</span>
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/summereyes/"
                    target="_blank"
                    className="text-gray-400 hover:text-summer-base"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-sm font-semibold uppercase tracking-wider text-summer-base">
                      Product
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      <li>
                        <Link href="/#features-segment">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Features
                          </a>
                        </Link>
                      </li>

                      <li>
                        <Link href="https://chrome.google.com/webstore/detail/summereyes-ai-productivit/hipjhgcpienlgjnecbhdaipgghicigei">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Download Extension{" "}
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-sm font-semibold uppercase tracking-wider text-summer-base">
                      Support
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      <li>
                        <Link href="/faqs">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            FAQs
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/support">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Documentation
                          </a>
                        </Link>
                      </li>

                      <li>
                        <a
                          href="mailto:support@summereyes.ai?subject=Hi there! I need some support please."
                          className="text-base text-gray-600 hover:text-summer-base"
                        >
                          {" "}
                          Contact{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-sm font-semibold uppercase tracking-wider text-summer-base">
                      Company
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      <li>
                        <Link href="/announcements">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Announcements
                          </a>
                        </Link>
                      </li>
                    </ul>
                    <ul role="list" className="mt-4 space-y-4">
                      <li>
                        <Link href="/about">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            About
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/careers">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Jobs
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-sm font-semibold uppercase tracking-wider text-summer-base">
                      Legal
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      <li>
                        <Link href="/privacyPolicy">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Privacy
                          </a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/terms">
                          <a className="text-base text-gray-600 hover:text-summer-base">
                            Terms
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 border-t border-gray-200 pt-8">
              <p className="text-sm text-gray-400 xl:text-center">
                &copy; 2022 SummerEyes Technology Limited. All rights reserved.
              </p>
              <p className="text-sm text-gray-400 xl:text-center">
                SummerEyes Technology Limited is a company registered in England
                and Wales with company number 14186752.
              </p>
            </div>
          </div>
        </ContentPlacer>
      </footer>
    </>
  );
}
