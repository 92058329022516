import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import firebase from "firebase/app";
import ContentPlacer from "./ContentPlacer";
import SummerEyesButton from "./branding/SummerEyesButton";
import SummerEyesLogo from "./branding/SummerEyesLogo";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [navBg, setNavBg] = useState(props.isDark ? false : true);

  const changeNavBg = () => {
    if (window.scrollY >= 690) {
      setNavBg(true);
    } else if (props.isDark) {
      setNavBg(false);
    }
  };

  const navBarClasses =
    "ml-3 mb-3 appearance-none rounded bg-transparent px-4 py-2 text-xs font-bold uppercase outline-none ease-linear md:mb-0 " +
    (navBg
      ? "text-summer-base hover:text-summer-bright"
      : "text-white hover:text-summer-contrast-highlight");

  const navIconClasses = navBg
    ? "fas fa-bars text-slate-900"
    : "fas fa-bars text-slate-100";

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <>
      <nav className="navbar-expand-lg top-0 z-50 mt-6 flex w-full flex-wrap border-0 py-3 md:sticky ">
        <ContentPlacer customMargins="xl:mx-auto md:mx-10 mx-8">
          <div className="flex flex-wrap">
            <div className="relative flex h-full w-full grow justify-between align-middle md:static md:block md:w-auto md:justify-start">
              <Link href="/">
                <a href="/">
                  <SummerEyesLogo lightMode={navBg} className="h-12 w-auto " />
                </a>
              </Link>
              <button
                className="block cursor-pointer rounded border border-solid border-transparent bg-transparent px-3 py-1 text-xl leading-none outline-none focus:outline-none md:hidden"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i className={"fas fa-bars " + navIconClasses}></i>
              </button>
            </div>
            <div
              className={
                "bg-summer-blue flex-grow items-center md:flex md:bg-opacity-0 md:shadow-none" +
                (navbarOpen ? " block" : " hidden")
              }
            >
              <ul className="mr-auto flex list-none flex-col md:flex-row">
                <li className="flex items-center"></li>
              </ul>
              <ul className="flex list-none flex-col md:ml-auto md:flex-row">
                {/*INTERIM: See if we can fix why if we use Next's Link element className doesnt work?? think its part of the migration */}
                <li className="flex justify-center" key="1">
                  <a href="/support" className={navBarClasses} type="button">
                    Support
                  </a>
                </li>
                <li className="flex justify-center" key="2">
                  <a href="/faqs" className={navBarClasses} type="button">
                    FAQs
                  </a>
                </li>
                {!props.showSignOut && [
                  <li className="flex justify-center" key="3">
                    <a href="/register" className={navBarClasses} type="button">
                      Register
                    </a>
                  </li>,
                  <li className="flex justify-center" key="4">
                    <SummerEyesButton
                      customTailwind="uppercase font-bold text-xs md:ml-4 mx-auto"
                      href="/login"
                      designStyle={navBg ? "positivePath" : "plainWhite"}
                    >
                      Sign in
                    </SummerEyesButton>
                  </li>,
                ]}
                {props.showSignOut && (
                  <li className="flex justify-center" key="5">
                    <SummerEyesButton
                      customTailwind="uppercase font-bold text-xs ml-4"
                      href=""
                      designStyle="negativePath"
                      onClick={async () => {
                        await firebase.auth().signOut();
                        window.location.href = "/"; // TODO: Check this is the right routing
                      }}
                    >
                      Sign out
                    </SummerEyesButton>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </ContentPlacer>
      </nav>
    </>
  );
}
