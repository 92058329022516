import Link from "next/link";
// INTERIM: Document this & write it up in the design system
/*
    Props:
        - href
        - onClick
        - children: Item going inside the button
        - full: boolean to take up full width
        - left: align button to left
        - customTailwind: Tailwind classes added to the button on top of the standard
        - designStyle: default is highlight colour, with others available:
            - negativePath: Red gradient version
            - positivePath: Blue gradient
        - disabled: Grayed out button that can't be selected
        - TODO: Implement loading state
*/

export default function SummerEyesButton(props) {
  // One button for the website, that has to work on mobile as well as desktop

  var width = props.full ? " w-full" : " ";

  if (props.disabled) {
    return (
      <>
        {/*Disabled button */}
        <button
          type={props.formSubmit ? "submit" : "button"}
          disabled
          className={
            "group relative m-auto flex justify-center rounded-full bg-gray-300 py-2 px-4 text-sm font-medium text-gray-500 " +
            width +
            " " +
            props.customTailwind
          }
        >
          {props.children}
        </button>
      </>
    );
  }

  var alignment = props.left ? " " : "m-auto flex justify-center";

  var colorClasses =
    " text-summer-base hover:text-summer-base-400 bg-gradient-to-r from-summer-contrast-highlight via-summer-light-highlight to-summer-contrast-highlight hover:from-summer-contrast-highlight hover:via-summer-contrast-highlight hover:to-summer-contrast-highlight focus:ring-summer-contrast-highlight"; // default is left to right blue
  if (props.designStyle == "negativePath") {
    // red gradient version
    colorClasses =
      " text-summer-base hover:text-gray-100 bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-b font-medium focus:ring-pink-500";
  }
  if (props.designStyle == "positivePath") {
    // blue gradient from left to right
    colorClasses =
      " text-gray-100 hover:text-white bg-gradient-to-r hover:to-summer-bright from-summer-bright to-summer-light-highlight-600 focus:ring-summer-bright ";
  }
  if (props.designStyle == "plainWhite") {
    // plain white button
    colorClasses = colorClasses =
      " text-gray-900 hover:text-summer-bright bg-white focus:ring-summer-bright focus:text-summer-bright";
  }
  if (props.designStyle == "noBg") {
    // plain white button
    colorClasses = colorClasses =
      " bg-none focus:ring-summer-bright focus:text-summer-bright";
    return (
      <>
        {/*Default button */}
        <Link href={props.href ?? ""}>
          <button
            type={props.formSubmit ? "submit" : "button"}
            onClick={props.onClick}
            className={
              "group relative rounded-full py-2 px-4 text-sm font-medium hover:ring-2 focus:outline-none focus:ring-2 focus:ring-offset-2" +
              colorClasses +
              " " +
              props.customTailwind +
              " " +
              alignment +
              width
            }
          >
            {props.children}
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      {/*Default button */}
      <Link href={props.href ?? ""}>
        <button
          type={props.formSubmit ? "submit" : "button"}
          onClick={props.onClick}
          className={
            "group relative rounded-full py-2 px-4 text-sm font-medium shadow-md hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2" +
            colorClasses +
            " " +
            props.customTailwind +
            " " +
            alignment +
            width
          }
        >
          {props.children}
        </button>
      </Link>
    </>
  );
}
