import React from "react";

/*
    ContentPlacer: Wrapper component for content that isn't background related -> ensures everything lines up appropriately
*/
export default function ContentPlacer(props) {
  return (
    <div className="xl:min-w-6xl mx-8 grow md:mx-10  md:max-w-none xl:mx-auto xl:max-w-6xl">
      {props.children}
    </div>
  );
}
