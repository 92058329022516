import React from "react";

/*
    SummerEyesLogo(props):
        - Resizable SummerEyes logo that can be placed in text
        - Properties:
            - className: Tailwind class system that can be applied to the svg
            - lightMode: Boolean value of whether to display light version of logo (i.e., dark fill for white background)
*/

export default function SummerEyesLogo(props) {
  if (props.lightMode) {
    return (
      <>
        <svg
          width="1087"
          height="254"
          viewBox="0 0 1087 254"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={props.className}
        >
          <path
            d="M168.377 115.635C190.924 154.688 177.543 204.625 138.49 227.172C99.4371 249.719 49.5001 236.339 26.9528 197.286C4.40549 158.232 17.7861 108.296 56.8391 85.7482C95.8922 63.2009 145.829 76.5815 168.377 115.635Z"
            fill="url(#paint0_linear_1_767)"
          />
          <path
            d="M177.977 172.139C180.537 159.066 179.86 145.566 176.005 132.815C172.15 120.065 165.233 108.451 155.859 98.9868C146.485 89.5227 134.938 82.4959 122.225 78.519C109.511 74.5422 96.0182 73.7362 82.9219 76.1713C82.9219 76.1713 80.1832 126.815 102.623 149.47C125.062 172.124 177.977 172.139 177.977 172.139Z"
            fill="url(#paint1_linear_1_767)"
          />
          <g filter="url(#filter0_bi_1_767)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M216.597 97.6013C216.597 63.9783 189.34 36.7214 155.717 36.7214C122.094 36.7214 94.8368 63.9783 94.8368 97.6013C94.8368 131.224 122.094 158.481 155.717 158.481C189.34 158.481 216.597 131.224 216.597 97.6013ZM237.318 97.6013C237.318 52.534 200.784 15.9998 155.717 15.9998C110.649 15.9998 74.1152 52.534 74.1152 97.6013C74.1152 142.669 110.649 179.203 155.717 179.203C200.784 179.203 237.318 142.669 237.318 97.6013Z"
              fill="#0A083D"
            />
          </g>
          <path
            d="M310.237 174.591C303.49 174.591 297.532 173.452 292.363 171.174C287.193 168.808 283.119 165.478 280.14 161.185C277.161 156.804 275.628 151.503 275.54 145.282H293.283C293.458 149.576 294.991 153.212 297.883 156.191C300.862 159.082 304.936 160.528 310.105 160.528C314.574 160.528 318.122 159.477 320.751 157.374C323.379 155.183 324.694 152.292 324.694 148.7C324.694 144.932 323.511 141.997 321.145 139.894C318.867 137.791 315.8 136.083 311.945 134.768C308.09 133.454 303.972 132.052 299.591 130.563C292.494 128.109 287.062 124.955 283.294 121.1C279.614 117.245 277.774 112.119 277.774 105.723C277.687 100.291 278.957 95.6469 281.586 91.7917C284.302 87.8489 287.982 84.8261 292.626 82.7233C297.269 80.5328 302.614 79.4376 308.66 79.4376C314.793 79.4376 320.181 80.5328 324.825 82.7233C329.556 84.9137 333.236 87.9804 335.865 91.9232C338.581 95.866 340.027 100.554 340.202 105.986H322.197C322.109 102.744 320.838 99.8964 318.385 97.4431C316.02 94.9022 312.69 93.6317 308.397 93.6317C304.717 93.5441 301.606 94.4641 299.065 96.3917C296.612 98.2317 295.385 100.948 295.385 104.54C295.385 107.607 296.349 110.06 298.277 111.9C300.204 113.652 302.833 115.142 306.162 116.369C309.492 117.595 313.303 118.909 317.597 120.311C322.153 121.888 326.315 123.728 330.082 125.831C333.85 127.934 336.873 130.738 339.151 134.243C341.429 137.66 342.568 142.084 342.568 147.517C342.568 152.336 341.341 156.804 338.888 160.922C336.434 165.04 332.798 168.37 327.979 170.911C323.16 173.364 317.246 174.591 310.237 174.591Z"
            fill="#0A083D"
          />
          <path
            d="M378.668 174.591C370.52 174.591 364.211 172.05 359.743 166.968C355.362 161.886 353.171 154.439 353.171 144.625V107.826H369.862V143.048C369.862 148.656 371.001 152.949 373.279 155.928C375.558 158.907 379.15 160.397 384.056 160.397C388.7 160.397 392.512 158.732 395.491 155.402C398.557 152.073 400.091 147.429 400.091 141.471V107.826H416.913V173.014H402.062L400.748 161.974C398.732 165.829 395.797 168.895 391.942 171.174C388.175 173.452 383.75 174.591 378.668 174.591Z"
            fill="#0A083D"
          />
          <path
            d="M431.554 173.014V107.826H446.405L447.851 116.631C449.954 113.477 452.714 110.98 456.131 109.14C459.635 107.212 463.666 106.249 468.222 106.249C478.298 106.249 485.439 110.148 489.645 117.946C492.01 114.353 495.165 111.506 499.107 109.403C503.138 107.3 507.519 106.249 512.25 106.249C520.749 106.249 527.276 108.79 531.833 113.871C536.389 118.953 538.667 126.401 538.667 136.214V173.014H521.844V137.791C521.844 132.184 520.749 127.89 518.559 124.911C516.456 121.932 513.17 120.443 508.701 120.443C504.145 120.443 500.465 122.108 497.662 125.437C494.945 128.766 493.587 133.41 493.587 139.368V173.014H476.765V137.791C476.765 132.184 475.67 127.89 473.479 124.911C471.289 121.932 467.915 120.443 463.359 120.443C458.891 120.443 455.255 122.108 452.451 125.437C449.735 128.766 448.377 133.41 448.377 139.368V173.014H431.554Z"
            fill="#0A083D"
          />
          <path
            d="M552.01 173.014V107.826H566.861L568.307 116.631C570.409 113.477 573.169 110.98 576.587 109.14C580.091 107.212 584.122 106.249 588.678 106.249C598.754 106.249 605.895 110.148 610.1 117.946C612.466 114.353 615.62 111.506 619.563 109.403C623.594 107.3 627.974 106.249 632.706 106.249C641.205 106.249 647.732 108.79 652.288 113.871C656.844 118.953 659.123 126.401 659.123 136.214V173.014H642.3V137.791C642.3 132.184 641.205 127.89 639.014 124.911C636.911 121.932 633.626 120.443 629.157 120.443C624.601 120.443 620.921 122.108 618.117 125.437C615.401 128.766 614.043 133.41 614.043 139.368V173.014H597.221V137.791C597.221 132.184 596.125 127.89 593.935 124.911C591.744 121.932 588.371 120.443 583.815 120.443C579.346 120.443 575.71 122.108 572.907 125.437C570.19 128.766 568.832 133.41 568.832 139.368V173.014H552.01Z"
            fill="#0A083D"
          />
          <path
            d="M704.139 174.591C697.568 174.591 691.741 173.189 686.66 170.385C681.578 167.581 677.591 163.638 674.7 158.557C671.808 153.475 670.363 147.604 670.363 140.945C670.363 134.199 671.764 128.197 674.568 122.94C677.46 117.683 681.402 113.609 686.397 110.717C691.479 107.738 697.437 106.249 704.271 106.249C710.667 106.249 716.318 107.651 721.225 110.454C726.131 113.258 729.943 117.113 732.659 122.02C735.463 126.839 736.865 132.227 736.865 138.185C736.865 139.149 736.821 140.157 736.733 141.208C736.733 142.26 736.689 143.355 736.602 144.494H687.054C687.404 149.576 689.157 153.562 692.311 156.454C695.553 159.345 699.452 160.791 704.008 160.791C707.425 160.791 710.273 160.046 712.551 158.557C714.916 156.979 716.669 154.964 717.808 152.511H734.893C733.667 156.629 731.608 160.397 728.716 163.814C725.912 167.143 722.408 169.772 718.202 171.699C714.084 173.627 709.396 174.591 704.139 174.591ZM704.271 119.917C700.153 119.917 696.517 121.1 693.362 123.466C690.208 125.744 688.193 129.248 687.317 133.98H719.779C719.516 129.686 717.939 126.269 715.048 123.728C712.156 121.188 708.564 119.917 704.271 119.917Z"
            fill="#0A083D"
          />
          <path
            d="M748.385 173.014V107.826H763.368L764.945 120.049C767.31 115.843 770.508 112.513 774.539 110.06C778.657 107.519 783.476 106.249 788.996 106.249V123.991H784.264C780.585 123.991 777.299 124.561 774.407 125.7C771.516 126.839 769.238 128.81 767.573 131.614C765.996 134.418 765.208 138.317 765.208 143.311V173.014H748.385Z"
            fill="#0A083D"
          />
          <path
            d="M798.259 173.014V81.0147H858.321V94.5517H815.082V119.786H854.379V132.928H815.082V159.477H858.321V173.014H798.259Z"
            fill="#0A083D"
          />
          <path
            d="M879.366 201.927L894.48 168.676H890.537L865.172 107.826H883.44L901.709 153.694L920.766 107.826H938.64L897.24 201.927H879.366Z"
            fill="#0A083D"
          />
          <path
            d="M973.036 174.591C966.464 174.591 960.638 173.189 955.556 170.385C950.474 167.581 946.487 163.638 943.596 158.557C940.704 153.475 939.259 147.604 939.259 140.945C939.259 134.199 940.661 128.197 943.464 122.94C946.356 117.683 950.299 113.609 955.293 110.717C960.375 107.738 966.333 106.249 973.167 106.249C979.563 106.249 985.214 107.651 990.121 110.454C995.028 113.258 998.839 117.113 1001.56 122.02C1004.36 126.839 1005.76 132.227 1005.76 138.185C1005.76 139.149 1005.72 140.157 1005.63 141.208C1005.63 142.26 1005.59 143.355 1005.5 144.494H955.95C956.3 149.576 958.053 153.562 961.207 156.454C964.449 159.345 968.348 160.791 972.904 160.791C976.321 160.791 979.169 160.046 981.447 158.557C983.812 156.979 985.565 154.964 986.704 152.511H1003.79C1002.56 156.629 1000.5 160.397 997.612 163.814C994.809 167.143 991.304 169.772 987.098 171.699C982.98 173.627 978.293 174.591 973.036 174.591ZM973.167 119.917C969.049 119.917 965.413 121.1 962.259 123.466C959.104 125.744 957.089 129.248 956.213 133.98H988.675C988.412 129.686 986.835 126.269 983.944 123.728C981.053 121.188 977.46 119.917 973.167 119.917Z"
            fill="#0A083D"
          />
          <path
            d="M1044.22 174.591C1038.44 174.591 1033.36 173.671 1028.98 171.831C1024.6 169.903 1021.09 167.275 1018.46 163.945C1015.84 160.616 1014.26 156.76 1013.73 152.38H1030.69C1031.21 154.92 1032.61 157.111 1034.89 158.951C1037.26 160.703 1040.28 161.579 1043.96 161.579C1047.64 161.579 1050.31 160.835 1051.98 159.345C1053.73 157.856 1054.61 156.147 1054.61 154.219C1054.61 151.416 1053.38 149.532 1050.93 148.568C1048.47 147.517 1045.06 146.509 1040.68 145.545C1037.87 144.932 1035.02 144.187 1032.13 143.311C1029.24 142.435 1026.57 141.34 1024.12 140.025C1021.75 138.623 1019.82 136.871 1018.33 134.768C1016.84 132.578 1016.1 129.906 1016.1 126.751C1016.1 120.968 1018.38 116.106 1022.93 112.163C1027.58 108.22 1034.06 106.249 1042.38 106.249C1050.09 106.249 1056.23 108.045 1060.78 111.637C1065.43 115.23 1068.19 120.18 1069.06 126.488H1053.16C1052.2 121.669 1048.56 119.26 1042.25 119.26C1039.1 119.26 1036.64 119.873 1034.89 121.1C1033.23 122.327 1032.4 123.86 1032.4 125.7C1032.4 127.627 1033.67 129.161 1036.21 130.3C1038.75 131.439 1042.12 132.49 1046.33 133.454C1050.88 134.505 1055.04 135.688 1058.81 137.003C1062.67 138.229 1065.73 140.113 1068.01 142.654C1070.29 145.107 1071.43 148.656 1071.43 153.299C1071.52 157.33 1070.47 160.966 1068.27 164.208C1066.08 167.45 1062.93 169.991 1058.81 171.831C1054.69 173.671 1049.83 174.591 1044.22 174.591Z"
            fill="#0A083D"
          />
          <defs>
            <filter
              id="filter0_bi_1_767"
              x="69.5465"
              y="11.431"
              width="172.341"
              height="172.341"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.28437" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1_767"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1_767"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.95949" />
              <feGaussianBlur stdDeviation="0.979743" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.33 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect2_innerShadow_1_767"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1_767"
              x1="-105.881"
              y1="488.986"
              x2="170.798"
              y2="78.0146"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.652431" stopColor="#0F6FFF" />
              <stop offset="0.984111" stopColor="#81E6F7" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1_767"
              x1="145.934"
              y1="93.464"
              x2="114.571"
              y2="154.697"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#66F0CA" />
              <stop offset="0.857061" stopColor="#0798BC" />
            </linearGradient>
          </defs>
        </svg>
      </>
    );
  }

  return (
    <>
      <svg
        width="1087"
        height="254"
        viewBox="0 0 1087 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M168.377 115.635C190.924 154.688 177.543 204.625 138.49 227.172C99.4371 249.719 49.5001 236.339 26.9528 197.286C4.40549 158.232 17.7861 108.296 56.8391 85.7482C95.8922 63.2009 145.829 76.5815 168.377 115.635Z"
          fill="url(#paint0_linear_1_759)"
        />
        <path
          d="M177.977 172.139C180.537 159.066 179.86 145.566 176.005 132.815C172.15 120.065 165.233 108.451 155.859 98.9868C146.485 89.5227 134.938 82.4959 122.225 78.519C109.511 74.5422 96.0182 73.7362 82.9219 76.1713C82.9219 76.1713 80.1832 126.815 102.623 149.47C125.062 172.124 177.977 172.139 177.977 172.139Z"
          fill="url(#paint1_linear_1_759)"
        />
        <g filter="url(#filter0_bi_1_759)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M216.597 97.6014C216.597 63.9784 189.34 36.7215 155.717 36.7215C122.094 36.7215 94.8368 63.9784 94.8368 97.6014C94.8368 131.225 122.094 158.481 155.717 158.481C189.34 158.481 216.597 131.225 216.597 97.6014ZM237.318 97.6014C237.318 52.5341 200.784 15.9999 155.717 15.9999C110.649 15.9999 74.1152 52.5341 74.1152 97.6014C74.1152 142.669 110.649 179.203 155.717 179.203C200.784 179.203 237.318 142.669 237.318 97.6014Z"
            fill="white"
          />
        </g>
        <path
          d="M310.237 174.591C303.49 174.591 297.532 173.452 292.363 171.174C287.193 168.808 283.119 165.478 280.14 161.185C277.161 156.804 275.628 151.503 275.54 145.282H293.283C293.458 149.576 294.991 153.212 297.883 156.191C300.862 159.082 304.936 160.528 310.105 160.528C314.574 160.528 318.122 159.477 320.751 157.374C323.379 155.183 324.694 152.292 324.694 148.7C324.694 144.932 323.511 141.997 321.145 139.894C318.867 137.791 315.8 136.083 311.945 134.768C308.09 133.454 303.972 132.052 299.591 130.563C292.494 128.109 287.062 124.955 283.294 121.1C279.614 117.245 277.774 112.119 277.774 105.723C277.687 100.291 278.957 95.6469 281.586 91.7917C284.302 87.8489 287.982 84.8261 292.626 82.7233C297.269 80.5328 302.614 79.4376 308.66 79.4376C314.793 79.4376 320.181 80.5328 324.825 82.7233C329.556 84.9137 333.236 87.9804 335.865 91.9232C338.581 95.866 340.027 100.554 340.202 105.986H322.197C322.109 102.744 320.838 99.8964 318.385 97.4431C316.02 94.9022 312.69 93.6317 308.397 93.6317C304.717 93.5441 301.606 94.4641 299.065 96.3917C296.612 98.2317 295.385 100.948 295.385 104.54C295.385 107.607 296.349 110.06 298.277 111.9C300.204 113.652 302.833 115.142 306.162 116.369C309.492 117.595 313.303 118.909 317.597 120.311C322.153 121.888 326.315 123.728 330.082 125.831C333.85 127.934 336.873 130.738 339.151 134.243C341.429 137.66 342.568 142.084 342.568 147.517C342.568 152.336 341.341 156.804 338.888 160.922C336.434 165.04 332.798 168.37 327.979 170.911C323.16 173.364 317.246 174.591 310.237 174.591Z"
          fill="white"
        />
        <path
          d="M378.668 174.591C370.52 174.591 364.211 172.05 359.743 166.968C355.362 161.886 353.171 154.439 353.171 144.625V107.826H369.862V143.048C369.862 148.656 371.001 152.949 373.279 155.928C375.558 158.907 379.15 160.397 384.056 160.397C388.7 160.397 392.512 158.732 395.491 155.402C398.557 152.073 400.091 147.429 400.091 141.471V107.826H416.913V173.014H402.062L400.748 161.974C398.732 165.829 395.797 168.895 391.942 171.174C388.175 173.452 383.75 174.591 378.668 174.591Z"
          fill="white"
        />
        <path
          d="M431.554 173.014V107.826H446.405L447.851 116.631C449.954 113.477 452.714 110.98 456.131 109.14C459.635 107.212 463.666 106.249 468.222 106.249C478.298 106.249 485.439 110.148 489.645 117.946C492.01 114.353 495.165 111.506 499.107 109.403C503.138 107.3 507.519 106.249 512.25 106.249C520.749 106.249 527.276 108.79 531.833 113.871C536.389 118.953 538.667 126.401 538.667 136.214V173.014H521.844V137.791C521.844 132.184 520.749 127.89 518.559 124.911C516.456 121.932 513.17 120.443 508.701 120.443C504.145 120.443 500.465 122.108 497.662 125.437C494.945 128.766 493.587 133.41 493.587 139.368V173.014H476.765V137.791C476.765 132.184 475.67 127.89 473.479 124.911C471.289 121.932 467.915 120.443 463.359 120.443C458.891 120.443 455.255 122.108 452.451 125.437C449.735 128.766 448.377 133.41 448.377 139.368V173.014H431.554Z"
          fill="white"
        />
        <path
          d="M552.01 173.014V107.826H566.861L568.307 116.631C570.409 113.477 573.169 110.98 576.587 109.14C580.091 107.212 584.122 106.249 588.678 106.249C598.754 106.249 605.895 110.148 610.1 117.946C612.466 114.353 615.62 111.506 619.563 109.403C623.594 107.3 627.974 106.249 632.706 106.249C641.205 106.249 647.732 108.79 652.288 113.871C656.844 118.953 659.123 126.401 659.123 136.214V173.014H642.3V137.791C642.3 132.184 641.205 127.89 639.014 124.911C636.911 121.932 633.626 120.443 629.157 120.443C624.601 120.443 620.921 122.108 618.117 125.437C615.401 128.766 614.043 133.41 614.043 139.368V173.014H597.221V137.791C597.221 132.184 596.125 127.89 593.935 124.911C591.744 121.932 588.371 120.443 583.815 120.443C579.346 120.443 575.71 122.108 572.907 125.437C570.19 128.766 568.832 133.41 568.832 139.368V173.014H552.01Z"
          fill="white"
        />
        <path
          d="M704.139 174.591C697.568 174.591 691.741 173.189 686.66 170.385C681.578 167.581 677.591 163.638 674.7 158.557C671.808 153.475 670.363 147.604 670.363 140.945C670.363 134.199 671.764 128.197 674.568 122.94C677.46 117.683 681.402 113.609 686.397 110.717C691.479 107.738 697.437 106.249 704.271 106.249C710.667 106.249 716.318 107.651 721.225 110.454C726.131 113.258 729.943 117.113 732.659 122.02C735.463 126.839 736.865 132.227 736.865 138.185C736.865 139.149 736.821 140.157 736.733 141.208C736.733 142.26 736.689 143.355 736.602 144.494H687.054C687.404 149.576 689.157 153.562 692.311 156.454C695.553 159.345 699.452 160.791 704.008 160.791C707.425 160.791 710.273 160.046 712.551 158.557C714.916 156.979 716.669 154.964 717.808 152.511H734.893C733.667 156.629 731.608 160.397 728.716 163.814C725.912 167.143 722.408 169.772 718.202 171.699C714.084 173.627 709.396 174.591 704.139 174.591ZM704.271 119.917C700.153 119.917 696.517 121.1 693.362 123.466C690.208 125.744 688.193 129.248 687.317 133.98H719.779C719.516 129.686 717.939 126.269 715.048 123.728C712.156 121.188 708.564 119.917 704.271 119.917Z"
          fill="white"
        />
        <path
          d="M748.385 173.014V107.826H763.368L764.945 120.049C767.31 115.843 770.508 112.513 774.539 110.06C778.657 107.519 783.476 106.249 788.996 106.249V123.991H784.264C780.585 123.991 777.299 124.561 774.407 125.7C771.516 126.839 769.238 128.81 767.573 131.614C765.996 134.418 765.208 138.317 765.208 143.311V173.014H748.385Z"
          fill="white"
        />
        <path
          d="M798.259 173.014V81.0147H858.321V94.5517H815.082V119.786H854.379V132.928H815.082V159.477H858.321V173.014H798.259Z"
          fill="white"
        />
        <path
          d="M879.366 201.927L894.48 168.676H890.537L865.172 107.826H883.44L901.709 153.694L920.766 107.826H938.64L897.24 201.927H879.366Z"
          fill="white"
        />
        <path
          d="M973.036 174.591C966.464 174.591 960.638 173.189 955.556 170.385C950.474 167.581 946.487 163.638 943.596 158.557C940.704 153.475 939.259 147.604 939.259 140.945C939.259 134.199 940.661 128.197 943.464 122.94C946.356 117.683 950.299 113.609 955.293 110.717C960.375 107.738 966.333 106.249 973.167 106.249C979.563 106.249 985.214 107.651 990.121 110.454C995.028 113.258 998.839 117.113 1001.56 122.02C1004.36 126.839 1005.76 132.227 1005.76 138.185C1005.76 139.149 1005.72 140.157 1005.63 141.208C1005.63 142.26 1005.59 143.355 1005.5 144.494H955.95C956.3 149.576 958.053 153.562 961.207 156.454C964.449 159.345 968.348 160.791 972.904 160.791C976.321 160.791 979.169 160.046 981.447 158.557C983.812 156.979 985.565 154.964 986.704 152.511H1003.79C1002.56 156.629 1000.5 160.397 997.612 163.814C994.809 167.143 991.304 169.772 987.098 171.699C982.98 173.627 978.293 174.591 973.036 174.591ZM973.167 119.917C969.049 119.917 965.413 121.1 962.259 123.466C959.104 125.744 957.089 129.248 956.213 133.98H988.675C988.412 129.686 986.835 126.269 983.944 123.728C981.053 121.188 977.46 119.917 973.167 119.917Z"
          fill="white"
        />
        <path
          d="M1044.22 174.591C1038.44 174.591 1033.36 173.671 1028.98 171.831C1024.6 169.903 1021.09 167.275 1018.46 163.945C1015.84 160.616 1014.26 156.76 1013.73 152.38H1030.69C1031.21 154.92 1032.61 157.111 1034.89 158.951C1037.26 160.703 1040.28 161.579 1043.96 161.579C1047.64 161.579 1050.31 160.835 1051.98 159.345C1053.73 157.856 1054.61 156.147 1054.61 154.219C1054.61 151.416 1053.38 149.532 1050.93 148.568C1048.47 147.517 1045.06 146.509 1040.68 145.545C1037.87 144.932 1035.02 144.187 1032.13 143.311C1029.24 142.435 1026.57 141.34 1024.12 140.025C1021.75 138.623 1019.82 136.871 1018.33 134.768C1016.84 132.578 1016.1 129.906 1016.1 126.751C1016.1 120.968 1018.38 116.106 1022.93 112.163C1027.58 108.22 1034.06 106.249 1042.38 106.249C1050.09 106.249 1056.23 108.045 1060.78 111.637C1065.43 115.23 1068.19 120.18 1069.06 126.488H1053.16C1052.2 121.669 1048.56 119.26 1042.25 119.26C1039.1 119.26 1036.64 119.873 1034.89 121.1C1033.23 122.327 1032.4 123.86 1032.4 125.7C1032.4 127.627 1033.67 129.161 1036.21 130.3C1038.75 131.439 1042.12 132.49 1046.33 133.454C1050.88 134.505 1055.04 135.688 1058.81 137.003C1062.67 138.229 1065.73 140.113 1068.01 142.654C1070.29 145.107 1071.43 148.656 1071.43 153.299C1071.52 157.33 1070.47 160.966 1068.27 164.208C1066.08 167.45 1062.93 169.991 1058.81 171.831C1054.69 173.671 1049.83 174.591 1044.22 174.591Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_bi_1_759"
            x="69.5465"
            y="11.4311"
            width="172.341"
            height="172.341"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.28437" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1_759"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1_759"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.95949" />
            <feGaussianBlur stdDeviation="0.979743" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.33 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_1_759"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1_759"
            x1="-105.881"
            y1="488.986"
            x2="170.798"
            y2="78.0146"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.652431" stopColor="#0F6FFF" />
            <stop offset="0.984111" stopColor="#81E6F7" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_759"
            x1="145.934"
            y1="93.464"
            x2="114.571"
            y2="154.697"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#66F0CA" />
            <stop offset="0.857061" stopColor="#0798BC" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
