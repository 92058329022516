/*
    PageDefault.js is an overarching wrapper component to be placed around all individual pages. It contains:
        - Key header items:
            - Title
            - Font import
            - Google Tag Manager
        - Navbar
        - Main body spacing & padding
        - Footer
*/

// imports (external)
import React from "react";
import Head from "next/head";

// imports (internal)
import Navbar from "./Navbar";
import Footer from "../components/Footer";

/*
    PageDefault: Wrapper component for all pages
    Expects the following props:
        - Component: The component to be rendered (nested inside the component tag)
        - pageTitle: Title of the webpage (passed as a prop)
*/
export default function PageDefault(props) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <meta
          name="google-site-verification"
          content="A3DWVsRkhx7CB09JBsVUOQ9pceltojB5tIGMTnTlny0"
        />

        {/*Stuff for open graph - rich social media links etc.*/}
        <meta property="og:title" content={props.pageTitle} />
        <meta
          property="og:description"
          content="Summarise any text on the internet. Instantly."
        />
        <meta
          property="og:image"
          content="https://publically-available-assets.s3.us-east-2.amazonaws.com/open_graph_image_1280x800.png"
        />
        <meta property="og:url" content="https://summereyes.ai" />

        <title>{props.pageTitle}</title>
      </Head>

      <div className={"m-0 flex min-h-screen w-full flex-col "}>
        <Navbar showSignOut={props.isAppPage} isDark={props.isDark} />
        <div className="">{props.children}</div>
        <Footer />
      </div>
    </>
  );
}
